<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(title)="data">
              {{ data.item.title }}
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/purchasing_orders/view/' + data.item.id"
                variant="outline-primary"
                size="sm"
              >
                Görüntüle
              </b-button>
            </template>
          </b-table>
        </b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'id',
          label: 'Sipariş No',
          thStyle: { width: '210px' },
        },
        {
          key: 'supplier',
          label: 'Tedarikçi',
        },
        {
          key: 'purchasing_status',
          label: 'Durum',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '210px' },
        },
      ],
      dataQuery: {
        select: [
          'com_purchasing_orders.id as id',
          'com_suppliers.title as supplier',
          'com_purchasing_statuses.title as purchasing_status',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['purchasingOrders/getPurchasingOrders']
    },
    dataCount() {
      return this.$store.getters['purchasingOrders/getPurchasingOrdersCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('purchasingOrders/purchasingOrders', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
